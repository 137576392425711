import { CHANGE_ADDONS_VALUE } from "../actions/actionType";

const initialState = {
  form: {
    type: "",
    data: {
      confidentiality: {
        editable: false,
        content:
          "IMPORTANTE: O conteúdo deste e-mail e quaisquer anexos são confidenciais. É estritamente proibido compartilhar qualquer parte desta mensagem com terceiros, sem o consentimento por escrito do remetente. Se você recebeu esta mensagem por engano, responda a esta mensagem e prossiga com sua exclusão, para que possamos garantir que tal erro não ocorra no futuro.",
      },
      environmental: {
        editable: false,
        content:
          "Por favor, não imprima este e-mail a menos que seja necessário. Cada e-mail não impresso ajuda o meio ambiente.",
      },
      custom: {
        editable: true,
        content: "",
      },
    },
    fontSize: 12,
    width: 450,
    selected: "confidentiality",
    amazonBadge: "",
    appleBadge: "",
    ebayBadge: "",
    googleBusinessBadge: "",
    googlePlayBadge: "",
    badgeShape: 1,
    badgeHeight: 50,
    badgePaddingTop: 15,
    badgeColor: 1,
  },
};

const addonsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case CHANGE_ADDONS_VALUE:
      let stringifiedPrevState = JSON.stringify(prevState);
      let newState = JSON.parse(stringifiedPrevState);

      if (action.key === "custom") {
        newState["form"]["data"]["custom"]["content"] = action.value;
        newState["form"]["selected"] = "custom";
      } else if (action.key === "reset") {
        newState = initialState;
      } else if (action.key === "selected") {
        if (
          action.value !== "custom" &&
          newState["form"]["selected"] === "custom"
        )
          newState["form"]["data"]["custom"]["content"] = "";

        newState["form"]["selected"] = action.value;
      } else newState["form"][action.key] = action.value;
      return {
        ...newState,
      };

    default:
      return prevState;
  }
};

export default addonsReducer;
