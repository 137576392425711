import React from "react";
import "./about.css";

function About() {
  return (
    <div className="about">
      <h1 style={{ fontSize: "3.5rem" }}>Gerador de assinatura HTML</h1>
      <hr className="new1" />
      <p>
        Crie sua assinatura em HTML para emails online totalmente gratuita.
        Assinaturas dinâmicas e fáceis de criar, basta preencher os campos e
        escolher os modelos. Nossas assinaturas são compatíveis com Gmail,
        Outlook e também com Apple Mail.
      </p>
    </div>
  );
}

export default About;
