import {
  ADD_SOCIAL,
  REMOVE_SOCIAL,
  UPDATE_SOCIAL,
} from "../actions/actionType";

const initialState = {
  selected: [
    {
      socialID: "Facebook",
      website: "https://www.facebook.com",
      source: "https://assinaturahtml.com.br/icons/facebook.png",

      value: "",
    },
    {
      socialID: "Twitter",
      website: "https://www.twitter.com",
      source: "https://assinaturahtml.com.br/icons/twitter.png",
      value: "",
    },
    {
      socialID: "LinkedIn",
      website: "https://www.linkedin.com",
      source: "https://assinaturahtml.com.br/icons/linkedin.png",
      value: "",
    },
  ],
  otherSocials: [
    {
      socialID: "Instagram",
      website: "https://www.instagram.com",
      source: "https://assinaturahtml.com.br/icons/instagram.png",
    },
    {
      socialID: "Pinterest",
      website: "https://www.pinterest.com",
      source: "https://assinaturahtml.com.br/icons/pinterest.png",
    },
    {
      socialID: "Youtube",
      website: "https://www.youtube.com",
      source: "https://assinaturahtml.com.br/icons/youtube.png",
    },
    {
      socialID: "Google+",
      website: "https://plus.google.com/+id",
      source: "https://assinaturahtml.com.br/icons/google-plus.png",
    },
    {
      socialID: "Hangouts",
      website: "https://hangouts.google.com/",
      source: "https://assinaturahtml.com.br/icons/hangouts.png",
    },
    {
      socialID: "Google Play",
      website: "https://play.google.com/store",
      source: "https://assinaturahtml.com.br/icons/google-play.png",
    },
    {
      socialID: "Tumblr",
      website: "https://www.tumblr.com",
      source: "https://assinaturahtml.com.br/icons/tumblr.png",
    },
    {
      socialID: "TripAdvisor",
      website: "https://www.tripadvisor.com/id",
      source: "https://assinaturahtml.com.br/icons/tripadvisor.png",
    },
    {
      socialID: "Github",
      website: "https://www.github.com",
      source: "https://assinaturahtml.com.br/icons/github.png",
    },
    {
      socialID: "Gitlab",
      website: "https://www.gitlab.com/id",
      source: "https://assinaturahtml.com.br/icons/gitlab.png",
    },
    {
      socialID: "Snapchat",
      website: "https://www.snapchat.com",
      source: "https://assinaturahtml.com.br/icons/snapchat.png",
    },
    {
      socialID: "StackOverFlow",
      website: "https://www.stackoverflow.com/users/id",
      source: "https://assinaturahtml.com.br/icons/stack-overflow.png",
    },
    {
      socialID: "Quora",
      website: "https://www.quora.com/id",
      source: "https://assinaturahtml.com.br/icons/quora.png",
    },
    {
      socialID: "Behance",
      website: "https://www.behance.net/id",
      source: "https://assinaturahtml.com.br/icons/behance.png",
    },
    {
      socialID: "Medium",
      website: "https://www.medium.com/@id",
      source: "https://assinaturahtml.com.br/icons/medium.png",
    },
    {
      socialID: "Yelp",
      website: "https://www.yelp.com/user_details?userid=id",
      source: "https://assinaturahtml.com.br/icons/yelp.png",
    },
    {
      socialID: "QQ",
      website: "https://www.qq.com/",
      source: "https://assinaturahtml.com.br/icons/qq.png",
    },
    {
      socialID: "Skype",
      website: "https://www.skype.com/",
      source: "https://assinaturahtml.com.br/icons/skype.png",
    },
    {
      socialID: "Slack",
      website: "https://team.slack.com/",
      source: "https://assinaturahtml.com.br/icons/slack.png",
    },
    {
      socialID: "Flickr",
      website: "https://www.flickr.com/people/id",
      source: "https://assinaturahtml.com.br/icons/flickr.png",
    },
    {
      socialID: "Google Docs",
      website: "https://docs.google.com/file/d/id",
      source: "https://assinaturahtml.com.br/icons/google-drive.png",
    },
    {
      socialID: "Reverb Nation",
      website: "https://www.reverbnation.com/id",
      source: "https://assinaturahtml.com.br/icons/reverbnation.png",
    },
    {
      socialID: "Deviant Art",
      website: "https://id.deviantart.com",
      source: "https://assinaturahtml.com.br/icons/devianart.png",
    },
    {
      socialID: "Viadeo",
      website: "https://vieadeo.com/profile/id",
      source: "https://assinaturahtml.com.br/icons/viadeo.png",
    },
    {
      socialID: "Nextdoor",
      website: "https://www.nextdoor.com/agency-detail/id",
      source: "https://assinaturahtml.com.br/icons/nextdoor.png",
    },
    {
      socialID: "Vine",
      website: "https://vine.co/id",
      source: "https://assinaturahtml.com.br/icons/vine.png",
    },
    {
      socialID: "Stumble Upon",
      website: "https://www.stumbleupon.com",
      source: "https://assinaturahtml.com.br/icons/stumbleupon.png",
    },
    {
      socialID: "Weibo",
      website: "https://weibo.com",
      source: "https://assinaturahtml.com.br/icons/weibo.png",
    },
    {
      socialID: "Wechat",
      website: "https://wechat.com",
      source: "https://assinaturahtml.com.br/icons/wechat.png",
    },
    {
      socialID: "Zillow",
      website: "https://zillo.com/user/id",
      source: "https://assinaturahtml.com.br/icons/zillow.png",
    },
    {
      socialID: "Ello",
      website: "https://ello.co/id",
      source: "https://assinaturahtml.com.br/icons/ello.png",
    },
    {
      socialID: "Slideshare",
      website: "https://slideshare.com/id",
      source: "https://assinaturahtml.com.br/icons/slideshare.png",
    },
    {
      socialID: "500px",
      website: "https://500px.com/id",
      source: "https://assinaturahtml.com.br/icons/500px.png",
    },
    {
      socialID: "Picasa",
      website: "https://picasaweb.google.com/id",
      source: "https://assinaturahtml.com.br/icons/picasa.png",
    },
    {
      socialID: "Blogger",
      website: "https://blogger.com/profile/id",
      source: "https://assinaturahtml.com.br/icons/blogger.png",
    },
    {
      socialID: "Myspace",
      website: "https://myspace.com/id",
      source: "https://assinaturahtml.com.br/icons/myspace.png",
    },
    {
      socialID: "Wordpress",
      website: "https://wordpress.com/",
      source: "https://assinaturahtml.com.br/icons/wordpress.png",
    },
    {
      socialID: "Trulia",
      website: "https://trulia.com/id",
      source: "https://assinaturahtml.com.br/icons/trulia.png",
    },
    {
      socialID: "Renren",
      website: "https://renren-inc.com/",
      source: "https://assinaturahtml.com.br/icons/renren.png",
    },
    {
      socialID: "Ravelry",
      website: "https://ravelry.com/",
      source: "https://assinaturahtml.com.br/icons/ravelry.png",
    },
    {
      socialID: "Dribble",
      website: "https://dribble.com/id",
      source: "https://assinaturahtml.com.br/icons/dribble.png",
    },
    {
      socialID: "Vimeo",
      website: "https://vimeo.com/id",
      source: "https://assinaturahtml.com.br/icons/vimeo.png",
    },
    {
      socialID: "Snapchat",
      website: "https://snapchat.com/add/id",
      source: "https://assinaturahtml.com.br/icons/snapchat.png",
    },
    {
      socialID: "VK",
      website: "https://vk.com/id",
      source: "https://assinaturahtml.com.br/icons/vk.png",
    },
    {
      socialID: "OK",
      website: "https://ok.ru/profile/id",
      source: "https://assinaturahtml.com.br/icons/odnoklassniki.png",
    },
    {
      socialID: "IMDB",
      website: "https://imdb.com/name/id",
      source: "https://assinaturahtml.com.br/icons/imdb.png",
    },
    {
      socialID: "Myspace",
      website: "https://myspace.com/id",
      source: "https://assinaturahtml.com.br/icons/myspace.png",
    },
    {
      socialID: "Line",
      website: "https://line.me/r/id",
      source: "https://assinaturahtml.com.br/icons/line.png",
    },
    {
      socialID: "Viber",
      website: "https://viber.me/id",
      source: "https://assinaturahtml.com.br/icons/viber.png",
    },
    {
      socialID: "Whatsapp",
      website: "https://chat.whatsapp.com/id",
      source: "https://assinaturahtml.com.br/icons/whatsapp.png",
    },
    {
      socialID: "Kickstarter",
      website: "https://kickstarter.com/projects/id",
      source: "https://assinaturahtml.com.br/icons/kickstarter.png",
    },
    {
      socialID: "Upwork",
      website: "https://upwork.com/o/profiles/users/id",
      source: "https://assinaturahtml.com.br/icons/upwork.png",
    },
    {
      socialID: "Producthunt",
      website: "https://producthunt.com/@id",
      source: "https://assinaturahtml.com.br/icons/product-hunt.png",
    },
    {
      socialID: "Calendy",
      website: "https://calendy.com/@id",
      source: "https://assinaturahtml.com.br/icons/calendly.png",
    },
    {
      socialID: "Homify",
      website: "https://www.homify.com/@id",
      source: "https://assinaturahtml.com.br/icons/homify.png",
    },
    {
      socialID: "Airbnb",
      website: "https://www.airbnb.com/@id",
      source: "https://assinaturahtml.com.br/icons/airbnb.png",
    },
    {
      socialID: "Shopify",
      website: "https://www.shopify.com/@id",
      source: "https://assinaturahtml.com.br/icons/shopify.png",
    },
    {
      socialID: "Angellist",
      website: "https://angel.co/id",
      source: "https://assinaturahtml.com.br/icons/angellist.png",
    },
    {
      socialID: "Diaspora",
      website: "https://joindiaspora.com/people/id",
      source: "https://assinaturahtml.com.br/icons/diaspora.png",
    },
    {
      socialID: "Xing",
      website: "https://xing.com/profile/id",
      source: "https://assinaturahtml.com.br/icons/xing.png",
    },
    {
      socialID: "Houzz",
      website: "https://houzz.com/user/id",
      source: "https://assinaturahtml.com.br/icons/houzz.png",
    },
    {
      socialID: "Telegram",
      website: "https://telegram.me/id",
      source: "https://assinaturahtml.com.br/icons/telegram.png",
    },
    {
      socialID: "Reddit",
      website: "https://reddit.com/user/id",
      source: "https://assinaturahtml.com.br/icons/reddit.png",
    },
    {
      socialID: "Gitter",
      website: "https://gitter.im/id/chat",
      source: "https://assinaturahtml.com.br/icons/gitter.png",
    },
    {
      socialID: "Meetup",
      website: "https://meetup.com/id",
      source: "https://assinaturahtml.com.br/icons/meetup.png",
    },
    {
      socialID: "Ebay",
      website: "https://ebay.com/user/id",
      source: "https://assinaturahtml.com.br/icons/ebay.png",
    },
    {
      socialID: "Foursquare",
      website: "https://foursquare.com/user/id",
      source: "https://assinaturahtml.com.br/icons/foursquare.png",
    },
    {
      socialID: "Drupal",
      website: "https://drupal.org/",
      source: "https://assinaturahtml.com.br/icons/drupal.png",
    },
    {
      socialID: "Bitbucket",
      website: "https://bitbucket.org/user/id",
      source: "https://assinaturahtml.com.br/icons/bitbucket.png",
    },
    {
      socialID: "SoundCloud",
      website: "https://soundcloud.com/id",
      source: "https://assinaturahtml.com.br/icons/soundcloud.png",
    },
    {
      socialID: "Mixcloud",
      website: "https://mixcloud.com/id",
      source: "https://assinaturahtml.com.br/icons/mixcloud.png",
    },
    {
      socialID: "Patreon",
      website: "https://patreon.com/id",
      source: "https://assinaturahtml.com.br/icons/patreon.png",
    },
    {
      socialID: "Spotify",
      website: "https://open.spotify.com/user/id",
      source: "https://assinaturahtml.com.br/icons/spotify.png",
    },
    {
      socialID: "Apple music",
      website: "https://itunes.apple.com/app/id",
      source: "https://assinaturahtml.com.br/icons/appstore.png",
    },
    {
      socialID: "Amazon",
      website: "https://amazon.com/gp/profile/id",
      source: "https://assinaturahtml.com.br/icons/amazon.png",
    },
    {
      socialID: "Etsy",
      website: "https://etsy.com/shop/id",
      source: "https://assinaturahtml.com.br/icons/etsy.png",
    },
    {
      socialID: "Tinder",
      website: "https://tinder.com/id",
      source: "https://assinaturahtml.com.br/icons/tinder.png",
    },
    {
      socialID: "Netflix",
      website: "https://netflix.com/title/id",
      source: "https://assinaturahtml.com.br/icons/netflix.png",
    },
    {
      socialID: "Wikipedia",
      website: "https://wikipedia.org/wiki/id",
      source: "https://assinaturahtml.com.br/icons/wikipedia.png",
    },
    {
      socialID: "Bitcoin",
      website: "https://bitcoin.com/",
      source: "https://assinaturahtml.com.br/icons/bitcoin.png",
    },
    {
      socialID: "Discord",
      website: "https://discord.gg/id",
      source: "https://assinaturahtml.com.br/icons/discord.png",
    },
    {
      socialID: "Your Site",
      website: "https://your-site.com/feed.xml",
      source: "https://assinaturahtml.com.br/icons/rss.png",
    },
    {
      socialID: "Your-site",
      website: "https://www.your-site.com/",
      source: "https://assinaturahtml.com.br/icons/link.png",
    },
    {
      socialID: "location",
      website: "https://location",
      source: "https://assinaturahtml.com.br/icons/location.png",
    },
    {
      socialID: "location",
      website: "https://location",
      source: "https://assinaturahtml.com.br/icons/map.png",
    },
    {
      socialID: "location",
      website: "https://location",
      source: "https://assinaturahtml.com.br/icons/google-maps.png",
    },
  ],
};

const socialReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case ADD_SOCIAL:
      let newOtherSocials = [];
      let selectedSocial = null;
      prevState.otherSocials.forEach((social) => {
        if (social.socialID !== action.socialID) newOtherSocials.push(social);
        else selectedSocial = social;
      });

      let newSocial = prevState.selected;
      newSocial.push({
        socialID: action.socialID,
        website: selectedSocial.website,
        source: selectedSocial.source,
        value: "",
      });

      return {
        ...prevState,
        selected: newSocial,
        otherSocials: newOtherSocials,
      };

    case REMOVE_SOCIAL:
      if (action.socialID === null) {
        return {
          ...initialState,
        };
      }

      let newSelected = [];
      let unselectedSocial = null;
      prevState.selected.forEach((social) => {
        if (social.socialID !== action.socialID) newSelected.push(social);
        else unselectedSocial = social;
      });

      let newOtherSocial = prevState.otherSocials;
      newOtherSocial.push({
        socialID: action.socialID,
        website: unselectedSocial.website,
        source: unselectedSocial.source,
      });

      return {
        ...prevState,
        selected: newSelected,
        otherSocials: newOtherSocial,
      };

    case UPDATE_SOCIAL:
      let currSocial = [];
      prevState.selected.forEach((social) => {
        if (social.socialID === action.socialID) {
          let newValue = JSON.stringify(social);
          let newObject = JSON.parse(newValue);
          newObject["value"] = action.value;
          currSocial.push(newObject);
        } else currSocial.push(social);
      });

      return {
        ...prevState,
        selected: currSocial,
      };

    default:
      return prevState;
  }
};

export default socialReducer;
