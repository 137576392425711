import FirstImage from "../assets/1.svg";
import SecondImage from "../assets/2.svg";
import ThirdImage from "../assets/3.svg";
import FourthImage from "../assets/4.svg";
import FifthImage from "../assets/5.svg";
import SixthImage from "../assets/6.svg";
import SeventhImage from "../assets/7.svg";
import EighthImage from "../assets/8.svg";

import "./Uses.css";
import React from "react";

const Uses = () => {
  return (
    <div>
      <section className="totally-free">
        <div className="container">
          <div className="left-content">
            <h2>Gostou da Ferramenta?</h2>{" "}
            <p style={{ fontWeight: "bold" }}>
              Ela é gratuita e livre para ser usada! Ajude-nos a mantê-la
              online! Faça uma doação.
            </p>{" "}
          </div>
          <div className="right-content">
            <button
              className="button"
              onClick={() => {
                window.open(
                  "https://varios.link/pt-br/assinaturahtml",
                  "_blank"
                );
              }}
            >
              Quero Colaborar!
            </button>{" "}
          </div>{" "}
        </div>
      </section>
      <React.Fragment>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#FFFFFF",
            paddingTop: "2rem",
          }}
        >
          <h2>
            Quais as vantagens do nosso gerador de assinatura HTML gratuito?
          </h2>
          <br />
          <span></span>
        </div>

        <div className="uses-container">
          <div className="useCard">
            <div className="useCard--image">
              <img src={FirstImage} alt="Professional and polite" />
            </div>

            <div>
              <h3>Seja Profissional!</h3>
            </div>
            <div className="useCard--content">
              <p>Uma assinatura única com a identidade da sua empresa</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={SecondImage} alt="Cart" />
            </div>

            <div>
              <h3>Múltiplas áreas</h3>
            </div>
            <div className="useCard--content">
              <p>Crie assinaturas segmentado por setor</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={ThirdImage} alt="Logistics" />
            </div>

            <div>
              <h3>Aumente seus leads</h3>
            </div>
            <div className="useCard--content">
              <p>Com links clicáveis você será mais notado</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={FourthImage} alt="Healthcare" />
            </div>

            <div>
              <h3>Funciona com Gmail, Outlook, Apple Mail & outros</h3>
            </div>
            <div className="useCard--content">
              <p>Multiplataforma de fácil usabilidade.</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={FifthImage} alt="Travel" />
            </div>

            <div>
              <h3>Rápido e Fácil</h3>
            </div>
            <div className="useCard--content">
              <p>Crie modelos em apenas 4 minutos</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={SixthImage} alt="Real Estate" />
            </div>

            <div>
              <h3>Links Sociais</h3>
            </div>
            <div className="useCard--content">
              <p>Direcione para suas redes e ganhe engajamento</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={SeventhImage} alt="Real Estate" />
            </div>

            <div>
              <h3> Multiplos modelos</h3>
            </div>
            <div className="useCard--content">
              <p>São 10 templates para uso</p>
            </div>
          </div>
          <div className="useCard">
            <div className="useCard--image">
              <img src={EighthImage} alt="Real Estate" />
            </div>

            <div>
              <h3>Uma experiência imersiva</h3>
            </div>
            <div className="useCard--content">
              <p>
                Apresente a sua empresa para teus clientes com apenas um email
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Uses;
