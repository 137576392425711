import React from "react";
import Fab from "@mui/material/Fab";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const fabStyle = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
  zIndex: 10,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FAQ = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab
        onClick={handleClickOpen}
        sx={fabStyle}
        aria-label={"FAQs"}
        color={"primary"}
      >
        <h3>FAQs</h3>
      </Fab>
      <Dialog
        fullScreen
        style={{
          margin: "4vh 4vw",
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                textAlign: "center",
                fontFamily: "Recursive",
              }}
              variant="h6"
              component="div"
            >
              Perguntas Frequêntes
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ margin: "2rem" }}>
          <h4 style={{ color: "black" }}>1. A ferramente é gratuita?</h4>
          <span>Sim, totalmente gratuita e livre de mensalidades.</span>

          <h4 style={{ color: "black" }}>2. Como aplicar a assinatura?</h4>
          <span>
            Você pode exportar o HTML clicando em Código Fonte ou COPIAR e COLAR
            clicando em Copiar assinatura.
          </span>

          <h4 style={{ color: "black" }}>Q3. Existe limite de uso?</h4>
          <span>
            Não! Você pode usá-la quantas vezes for necessário. Crie assinaturas
            ilimitadas!
          </span>

          <h4 style={{ color: "black" }}>4. Funciona com qual sistema?</h4>
          <span>
            Qualquer leitor que aceite HTML, já testamos no GMAIL, OUTLOOK e
            APPLE MAIL.
          </span>

          <h4 style={{ color: "black" }}>
            5. Existe alguma forma de pedir ajuda?
          </h4>
          <span>Sim, através do email contato@pixelproject.com.br</span>
        </div>
      </Dialog>
    </div>
  );
};

export default FAQ;
